import { gql } from 'graphql-request';
import { createQuery } from '@fivehealth/botero';

const GRAPHQL_DOCUMENT = gql`
  query cleoMonitoringFormsets($isEnrollmentForm: Boolean) {
    cleoMonitoringFormsets(isEnrollmentForm: $isEnrollmentForm) {
      edges {
        node {
          monitoringForms {
            uid
            effectiveName
            isEnrollmentForm
            url
            patientFormCount
            patientFormWithSubmissionCount
            patientFormWithoutSubmissionCount
          }
        }
      }
    }
  }
`;

const useMonitoringFormsets = (args) =>
  createQuery({
    gqlDocument: GRAPHQL_DOCUMENT,
    queryType: 'query',
    baseQueryKey: 'monitoringFormsets',
    keepPreviousData: true,
    select: ({ cleoMonitoringFormsets }) => {
      const flatten = cleoMonitoringFormsets?.edges
        .flatMap(({ node }) => node?.monitoringForms)
        .filter((mnfs) => mnfs?.effectiveName)
        .map((mnfs) => {
          const toParams = args?.enrolledOnly
            ? {
                enrolledMonitoringFormIn: [mnfs.uid],
              }
            : { monitoringFormUidIn: [mnfs.uid] };

          return {
            ...mnfs,
            id: mnfs.uid,
            label: mnfs.effectiveName,
            url: mnfs.url,
            isEnrollmentForm: mnfs.isEnrollmentForm,
            toParams,
          };
        });
      return flatten;
    },
  })(args);

export default useMonitoringFormsets;
